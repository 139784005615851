








































































import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";
import AttendeeCard from "@/components/shared/AttendeeCard.vue";
import attendeeSearchVuexModule from "@/store/vuex-modules/searchAttendeeData";

import { AttendeeSearchResult } from "@/types/interfaces";

const attendeeSearchStore = getModule(attendeeSearchVuexModule);

@Component({
    components: {
        PageTitleHeader,
        AttendeeCard
    }
})
export default class AttendeeSearchResults extends Vue {
    get layoutOptions() {
        return this.$store.getters.layoutOptions;
    }

    get cssVariables() {
        return this.$store.getters.cssVariables;
    }

    get attendeeSearchResults() {
        if (attendeeSearchStore.attendeeSearchResults) {
            return attendeeSearchStore.attendeeSearchResults;
        } else {
            return [];
        }
    }

    get textHeader() {
        return this.$store.getters.getPageOptions("attendeeResults")?.textHeader
            ? this.$store.getters.getPageOptions("attendeeResults").textHeader
            : "Attendee Search Results";
    }

    /**
     * Lifecycle hooks
     */

    /**
     * Methods
     */
    getInitials(result: AttendeeSearchResult) {
        const returnValue = [];
        if (!result) return "";

        if (result?.firstName) {
            returnValue.push(result.firstName.charAt(0));
        }
        if (result?.lastName) {
            returnValue.push(result.lastName.charAt(0));
        }
        return returnValue.join("");
    }

    goToSearch() {
        this.$router.push("/attendees");
    }
}
